import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import PrivateNotificationsImg from '../../assets/images/private-notifications/private_notifications.webp'
import EncryptedImg from '../../assets/images/private-notifications/encrypted.svg'
import PrivacyImg from '../../assets/images/private-notifications/privacy.svg'
import SettingsImg from '../../assets/images/private-notifications/settings.svg'
import FullModeImg from '../../assets/images/private-notifications/full-mode.webp'
import SenderModeImg from '../../assets/images/private-notifications/sender-mode.webp'
import NoDetailsModeImg from '../../assets/images/private-notifications/no-details-mode.webp'
import EncryptedModeImg from '../../assets/images/private-notifications/encrypted.webp'

const PrivateNotifications = ({ data }) => {
    
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="BlueMail Private Notifications"
        postDescription="Designed to keep your sensitive information away from prying eyes, Private Notifications ensures that your notifications remain for your eyes only."
        postImage="/img/OG/og_image_private_notifications.png"
        postURL="features-functions/private-notifications"
        postSEO
      />
      <Navbar />
      <div className="features-functions private-notifications">
        <div className="container pt-120 pn">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 m-auto">
              <h1 className="text-center"><span>Private</span> Notifications</h1>
              <p>Designed to keep your sensitive information away from prying eyes, this feature ensures that your notifications remain for your eyes only. BlueMail's "Private Notifications" not only offer top-notch encryption but also the flexibility to choose how you receive your information. Understanding each mode helps you make the best choice for your privacy needs.</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12 col-md-12 col-xs-12 m-auto text-center">
                <div className="mainBkg">
                    <img src={PrivateNotificationsImg} alt="Private Notifications"/>
                </div>
            </div>
          </div>
        </div>
        <div className="container pt-120">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 m-auto">
                    <h2 className="text-center"><span>Features</span></h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-xs-12 m-auto text-center">
                    <div className="mainBkg ft p-3">
                        <img className="mt-5" src={EncryptedImg} alt="Encrypted"/>
                        <p className="mt-5 mb-1" style={{fontWeight: 'bold'}}>Encrypted Notifications</p>
                        <p className="small">Notifications arrive encrypted on your device, making them unreadable to anyone but you. <br className="pn-br"/>&nbsp;</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12 m-auto text-center">
                    <div className="mainBkg ft p-3">
                        <img className="mt-5" src={PrivacyImg} alt="Privacy"/>
                        <p className="mt-5 mb-1" style={{fontWeight: 'bold'}}>Privacy on Display</p>
                        <p className="small">Notifications are hidden until you actively open them. This means no more over-the-shoulder snooping!</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12 m-auto text-center">
                    <div className="mainBkg ft p-3">
                        <img className="mt-5" src={SettingsImg} alt="Settings"/>
                        <p className="mt-5 mb-1" style={{fontWeight: 'bold'}}>Customizable Settings</p>
                        <p className="small">Choose how much or how little notification information is displayed on your lock screen. <br className="pn-br sec"/>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container pt-120 pb-100">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 m-auto text-center">
                    <h2 className="text-center"><span>Customizable</span> Notification Modes</h2>
                    <p>BlueMail's "Private Notifications" feature offers three distinct modes, each providing a different level of privacy and encryption. Tailor your notification experience to suit your needs and comfort.</p>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="mainBkg modes">
                        <img src={FullModeImg} alt="Full Mode"/>
                        <img className="enc" src={EncryptedModeImg} alt="Encrypted Modes"/>
                        <p className="mb-2" style={{fontWeight: 'bold'}}>Full Preview Mode</p>
                        <p className="small mb-0" style={{color: '#407BFF'}}>How It Works?</p>
                        <p className="small">Receive fully detailed notifications, all while keeping the content encrypted and secure.</p>
                        <p className="small mb-0" style={{color: '#407BFF'}}>Ideal Usage</p>
                        <p className="small">Perfect for secure environments where you need complete information at your fingertips.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="mainBkg modes">
                        <img src={SenderModeImg} alt="Sender Only Mode"/>
                        <img className="enc" src={EncryptedModeImg} alt="Encrypted Modes"/>
                        <p className="mb-2" style={{fontWeight: 'bold'}}>Sender Only Mode</p>
                        <p className="small mb-0" style={{color: '#407BFF'}}>How It Works?</p>
                        <p className="small">This mode displays only the sender's name, keeping both the subject and the body of the message encrypted and hidden.</p>
                        <p className="small mb-0" style={{color: '#407BFF'}}>Ideal Usage</p>
                        <p className="small">Perfect for when you need to know who is contacting you without revealing any content of the message, maintaining a high level of privacy.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="mainBkg modes">
                        <img src={NoDetailsModeImg} alt="No Details Mode"/>
                        <img className="enc" src={EncryptedModeImg} alt="Encrypted Modes"/>
                        <p className="mb-2" style={{fontWeight: 'bold'}}>Hidden Mode</p>
                        <p className="small mb-0" style={{color: '#407BFF'}}>How It Works?</p>
                        <p className="small">Achieve maximum discretion. Notifications show no content details, ensuring complete privacy.</p>
                        <p className="small mb-0" style={{color: '#407BFF'}}>Ideal Usage</p>
                        <p className="small">Essential for high-security situations or public environments where privacy is a priority.</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default PrivateNotifications

export const query = graphql`
  query PrivateNotificationsQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
